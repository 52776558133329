import * as React from "react";
import { ToggleBackground } from "./ToggleBackground";
import { Toggle } from "./ToggleButton";
import styled from "styled-components";
import ShelfButton from "./ToggleSecondaryButton";
import { ButtonLink } from "../../../../classes/ButtonLink";

/**
 */
const ToggleButtons = styled.div`
  position: relative;
  padding-right: 26px;
`;

export const Toggles = ({
  togglewidth,
  handleToggle,
  isOpen
}: {
  togglewidth: number;
  handleToggle: () => void;
  isOpen: boolean;
}) => {
  return (
    <ToggleBackground togglewidth={togglewidth}>
      <ToggleButtons>
        <Toggle click={handleToggle} isOpen={isOpen} />
        <ShelfButton
          button={
            new ButtonLink({
              path: "/ask-an-expert/contact-a-regional-engineer",
              text: "Ask an Engineer",
              title: "Ask an Engineer",
              __typename: "ContentfulLink"
            })
          }
        />
      </ToggleButtons>
    </ToggleBackground>
  );
};
